import bg from '../assets/bg.png';
import chef from '../assets/about_bg2.png';
import about_bg from '../assets/about_bg.png';
import about_bg2 from '../assets/about_bg.webp';
import read_bg from '../assets/read_bg.png';
import read_bg2 from '../assets/read_bg.webp';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import logo from '../assets/logo.png';
import logo_black from '../assets/logo_black.png';
import overlaybg from '../assets/overlaybg.png';
import separatorblack from '../assets/separator.png';
import separatorwhite from '../assets/separatorwhite.png';
import welcome from '../assets/welcome5.png';
import welcome2 from '../assets/welcome5.webp';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award04 from '../assets/award04.png';
import award05 from '../assets/award05.png';
import award06 from '../assets/award06.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import NGP from '../assets/NGP.png';
import DDT from '../assets/DDT.png';
import CA from '../assets/CA.png';

export default {
  bg,
  chef,
  about_bg,
  about_bg2,
  read_bg,
  read_bg2,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  logo,
  logo_black,
  overlaybg,
  separatorblack,
  separatorwhite,
  welcome,
  welcome2,
  award01,
  award02,
  award03,
  award04,
  award05,
  award06,
  sign,
  quote,
  NGP,
  DDT,
  CA,
};
